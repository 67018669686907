import React from "react";

import Politica from "../../components/Politica/Politica";
import NavbarMobilePolitica from "../../components/NavbarMobile/NavbarMobilePolitica";

function MedicinasFlorestaPage() {
	return (
		<>
			<Politica />
			<NavbarMobilePolitica />
		</>
	);
}

export default MedicinasFlorestaPage;
